import axios from "axios";
import ApiUrl from "../config/ApiUrl";

class MunicipioService {

    buscarPorDescricao(descricao) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/municipio/buscar-por-descricao/${ descricao }`);
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10, sort: 'id,asc' }) {
        return axios.get(`${ApiUrl.vg}api/genesis-vg/v1/municipio/buscar-com-paginacao`, { params });
    }

    buscarPorId(id) {
        return axios.get(`${ApiUrl.vg}api/genesis-vg/v1/municipio/${id}`);
    }

    buscarComEmbarquePendente(codigoEstabelecimento) {
        return axios.get(`${ApiUrl.vg}api/genesis-vg/v1/municipio/destinos-com-embarque-pendente/${codigoEstabelecimento}`);
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/municipio/inativar/${ id }`);
    }
    
    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/municipio/ativar/${ id }`);
    }

    salvar(dados) {
        const config = {
            header: {
                'content-type': 'application/json'
            }
        }
        return axios.post(`${  ApiUrl.vg  }api/genesis-vg/v1/municipio`, dados, config);
    }

    sincronizar(params) {
        return axios.get(`${ApiUrl.vg}api/genesis-vg/v1/municipio/sincronizar`, {params});
    }
    
}
export default new MunicipioService();