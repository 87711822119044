<template>
    <div class="card">
        <div class="flex align-items-center">
            <i class="pi pi-building" style="font-size: 1.4rem"></i>
            <h3 style="margin:0px 5px;">Municípios</h3>
        </div>

        <hr />

        <DataTable responsiveLayout="stack" :value="data" v-model:selection="municipioSelecionado"
            selectionMode="single" showGridlines @sort="sort($event)">

            <template #header>
                <div class="flex align-items-center">
                    <div class="field mr-2">
                        <label>
                            <strong>Nome</strong>
                        </label>
                        <InputText class="w-full" v-model="filter.nome" />
                    </div>
                    <div class="field mr-2">
                        <label>
                            <strong>Código IBGE</strong>
                        </label>
                        <InputText class="w-full" v-model="filter.codigoIbge" />
                    </div>
                    <div class="field mr-2">
                        <label>
                            <strong>UF</strong>
                        </label>
                        <InputText class="w-full" v-model="filter.uf" />
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button label="Filtrar" icon="pi pi-filter" class="p-button-outlined p-button-info"
                                @click="carregarDados()"></Button>

                            <Button title="Limpar Filtro" icon="pi pi-filter-slash" class="p-button-outlined p-button-info"
                                style="margin-left: 1px;" @click="limparFiltro()"></Button>
                        </div>
                    </div>

                    <Button v-if="filter.situacao == 'ATIVO' && integration.visible" type="button" icon="pi pi-ban" label="Listar inativos"
                        class="p-button-outlined p-button-danger mr-2 mt-2"
                        @click="filter.situacao = 'INATIVO'; carregarDados()"></Button>

                    <Button v-if="filter.situacao == 'INATIVO' && integration.visible" type="button" icon="pi pi-check-circle"
                        label="Listar ativos" class="p-button-outlined mr-2 mt-2"
                        @click="filter.situacao = 'ATIVO'; carregarDados()"></Button>

                    <Button v-if="integration.visible" label="Cadastrar" icon="pi pi-plus" class="p-button-info mt-2 mr-2"
                        @click="cadastrar()"></Button>
                    
                    <Button v-if="!integration.visible" label="Sincronizar Todos" icon="pi pi-refresh" class="p-button-info mt-2 mr-2"
                        @click="sincronizar()"></Button>

                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="id" header="Código" sortable />
            <Column field="codigo" header="Código IBGE" sortable />
            <Column field="nome" header="Nome" sortable />
            <Column field="uf" header="UF" sortable/>
            
            <Column header="Ações" style="width:11rem;">
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button v-if="integration.visible" title="Editar" icon="pi pi-pencil"
                            class="p-button-rounded p-button-info flex-none p-2 m-2" @click="editar(data.id)"></Button>

                        <Button v-if="!integration.visible" title="Visualizar" icon="pi pi-eye"
                            class="p-button-rounded p-button-info flex-none p-2 m-2" @click="editar(data.id)"></Button>

                        <Button v-if="!integration.visible" title="Sincronizar" icon="pi pi-refresh"
                            class="p-button-rounded p-2 m-2 bg-cyan-500" @click="sincronizarCidade(data)"></Button>

                        <Button title="Visualizar histórico" icon="pi pi-list"
                            class="p-button-rounded p-button-warning flex-none p-2 m-2"
                            @click="visualizarHistorico(data)"></Button>

                        <Button v-if="data.ativo && integration.visible" title="Inativar" class="p-button-rounded flex-none p-button-danger"
                            icon="pi pi-ban" @click="confirmarAtivarOuInativar(data)"></Button>

                        <Button v-if="!data.ativo  && integration.visible" title="Ativar" class="p-button-rounded flex-none p-button-info"
                            icon="pi pi-check-circle" @click="confirmarAtivarOuInativar(data)"></Button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <Paginator v-model:first="primeiraLinha" :rows="qtdRegistroPorPagina" :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]" @page="onPage" />
    </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{ width: '300px' }" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Tem certeza que deseja <strong>{{ municipioSelecionado.ativo ? 'inativar' : 'ativar' }}</strong> o Município:
                <strong>{{ municipioSelecionado.id }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

    <Dialog v-model:visible="ativarOuInativarProgressDialog" :style="{ width: '600px' }" header="Sincronizando Municípios, Aguarde!" :modal="true">
        <ProgressBar :value="barraProgresso" style="height: 10px"/>
    </Dialog>
    
    <ModalHistoricoGenerico codigoPagina="MUNICIPIO" :id="municipioSelecionado.id"
        :descricao="municipioSelecionado.corretora" :visivel="historicoDialog" @fechar="ocultarHistorico()" />
</template>

<script>
import Formatacao from '../../../utilities/Formatacao';
import StorageService from '../../../service/StorageService';
import MunicipioService from '../../../service/MunicipioService'
import ModalHistoricoGenerico from '../../ModalHistoricoGenerico.vue';
import IntegracaoService from '../../../service/IntegracaoService';
import ProgressBar from 'primevue/progressbar';

export default {
    name: 'MunicipioList',
    components: { ModalHistoricoGenerico },
    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,
            data: [],
            filter: {},
            ordenar: 'id,asc',
            ativarOuInativarDialog: false,
            ativarOuInativarProgressDialog: false,
            sincronizaPage : 1,
            barraProgresso: 0,
            municipioSelecionado: {},
            historicoDialog: false,
            descricaoHistoricoDialog: null,
            integration: {},
            storage: StorageService.getControlePagina("MUNICIPIO"),
            storangeMessage: StorageService.getMensagemToast("MUNICIPIO")

        }
    },

    created() {
        this.filter.situacao = "ATIVO";

        if (this.storage.default == undefined) {
            this.filter = this.storage.filtros;
        }

        this.pagina = this.storage.numero;
        this.primeiraLinha = this.storage.firstRow;
        this.qtdRegistroPorPagina = this.storage.qtdRegistro;
        this.getIntegrationOptons();
        this.carregarDados();
        this.exibirMensagemToast();
    },

    methods: {
        exibirMensagemToast() {
            if (this.storangeMessage.active) {
                this.$toast.add({
                    severity: this.storangeMessage.severity,
                    summary: this.storangeMessage.summary,
                    detail: this.storangeMessage.detail,
                    life: 7500
                });
                StorageService.setMensagemToast("MUNICIPIO", 'info', '', '', false);
            }
        },
        
        cadastrar() {
            this.$router.push(`/municipio/cadastro`);
        },

        editar(municipioId) {
            this.$router.push(`/municipio/editar/${municipioId}`);
        },

        carregarDados() {
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;

            MunicipioService.buscarComPaginacao(this.filter)
                .then(({ data }) => {
                    if (data) {
                        this.data = data.content;
                        this.qtdRegistroPorPagina = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Falha ao consultar Municípios',
                        detail: 'Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!',
                        life: 7500
                    };

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });

            StorageService.setControlePaginaFilter("MUNICIPIO", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);
        },

        limparFiltro() {
            this.filter = {};
            this.filter.situacao = "ATIVO";
            this.carregarDados();
        },

        visualizarHistorico(municipio) {
            this.descricaoHistoricoDialog = "Município: " + municipio.id;
            this.historicoDialog = true;
            this.municipioSelecionado = municipio;
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.municipioSelecionado = {};
        },

        confirmarAtivarOuInativar(municipio) {
            this.municipioSelecionado = municipio;
            this.ativarOuInativarDialog = true;
        },

        ativarOuInativar() {
            if (this.municipioSelecionado.ativo) {
                MunicipioService.inativar(this.municipioSelecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Inativado',
                            detail: `O Município foi inativado com sucesso.`,
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.municipioSelecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity: 'error',
                            summary: 'Falha ao inativar',
                            detail: `Falha não mapeada.`,
                            life: 7500
                        };

                        if (!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                MunicipioService.ativar(this.municipioSelecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Ativada',
                            detail: `O Município foi ativado com sucesso.`,
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.municipioSelecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity: 'error',
                            summary: 'Falha ao ativar',
                            detail: `Falha não mapeada.`,
                            life: 7500
                        };

                        if (!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }

            this.ativarOuInativarDialog = false;
        },

        sincronizarCidade(municipio){
            let params = {}
            params["page"] = 1;
            params["pageSize"] = 1;
            params["nome"] = municipio.nome;
            params["siglaEstado"] = municipio.uf;
            MunicipioService.sincronizar(params)
            .then(({ data })=>{
                if (data) {console.log('sincronizarCidade', data);
                    if (!data.municipios[0]) {
                        this.toastMessage('error','Sincronizar Município', 'Falha ao sincronizar, município não possui código de IBGE válido no ERP.' );
                    } else {
                        this.toastMessage('success','Sincronizar Município',`Município código `+ data.municipios[0].id + " sincronizado com sucesso.");
                    }
                }
            })
            .catch(error => {
                if (!!error && !!error.response && !!error.response.data) {
                    this.toastMessage('error','Falha ao sincronizar', error.response.data);
                } else { 
                    this.toastMessage('error','Falha ao sincronizar', 'Falha ao sincronizar');
                }
            });
        },

        sincronizar(){
            let params = {}
            params["page"] = this.sincronizaPage;
            params["pageSize"] = 100;
            params["nome"] = "";
            params["siglaEstado"] = "";
            this.ativarOuInativarProgressDialog = true;
            this.barraProgresso = this.barraProgresso < 100 ? this.barraProgresso += 1 : this.barraProgresso += 0;
            MunicipioService.sincronizar(params)
            .then(({ data })=>{
                if (data) {
                    this.barraProgresso = this.barraProgresso < 100 ? this.barraProgresso += 1 : this.barraProgresso += 0;
                    if (data.responsePage.hasNext){
                        this.sincronizaPage += 1;
                        this.sincronizar();
                    } else {
                        this.barraProgresso = 0;
                        this.sincronizaPage = 1;
                        this.ativarOuInativarProgressDialog = false;
                    }
                }
            })
            .catch(error => {
                if (!!error && !!error.response && !!error.response.data) {
                    this.toastMessage('error','Falha ao sincronizar', error.response.data);
                } else { 
                    this.toastMessage('error','Falha ao sincronizar', 'Falha ao sincronizar');
                }

                this.ativarOuInativarProgressDialog = false;
                this.$toast.add(toast);
            });
        },

        getIntegrationOptons() {
            const paginaAtual = "MUNICIPIO";
            IntegracaoService.getIntegrationOptions(paginaAtual)
                .then(({ data }) => {
                    if (data) {
                        this.integration = data;
                    }
                })
                .catch(error => {
                    this.$toast.add({
                        severity: 'info',
                        summary: 'PARÂMETROS DE PÁGINA NÂO DEFINIDOS',
                        detail: 'Você deve cadastrar os parâmetros para esta página no menu lateral em item: Parâmetros de Integração.',
                        life: 10000
                    });                    
                });
        },

        formatDateCustom(date, format) {
            return Formatacao.formatDateCustom(date, format);
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows;
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField == 'codigo' ? 'codigoIbge' : event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${sortField},${sortOrder}`;
            this.carregarDados();
        },

        toastMessage(tipo, resumo, detalhe){
            const toast = {
                severity: tipo,
                summary: resumo,
                detail: detalhe,
                life: 7500
            };
            this.$toast.add(toast);
        },
    }
}
</script>