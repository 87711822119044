<template>
    <div class="card">
        <div class="flex justify-content-between">
            <div class="flex align-items-center">
                <i class="pi pi-building" style="font-size: 1.4rem"></i>
                <h3 style="margin:0px 5px;">
                    Município
                </h3>
            </div>
        </div>
        <hr />
        <div class="grid p-fluid formgrid mt-3">
            <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label>
                        <strong>Código</strong>
                    </label>
                    <InputText v-model="dto.id" :disabled="true" />
                </div>
            </div>           
            <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label>
                        <strong>Código IBGE<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dto.codigo" :disabled="integration.disable" />
                </div>
            </div>            
            <div class="col-12 sm:col-4 md:col-4 lg:col-4 xl:col-4">
                <div class="field">
                    <label>
                        <strong>Município<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dto.nome" :disabled="integration.disable" />
                </div>
            </div>
            <div class="col-12 sm:col-2 md:col-2 lg:col-2 xl:col-2">
                <div class="field">
                    <label>
                        <strong>UF<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dto.uf" :maxlength="2" :disabled="integration.disable" @keyup="removerCaracteresEspeciais" />
                </div>
            </div>
        </div>
        <hr />
        <div class="flex align-items-center mt-3">
            <Button v-if="integration.visible" type="button" label="Salvar" class="p-button-info mr-1" icon="pi pi-plus" @click="salvar()" :disabled="doubleClick"/>
            <Button v-if="integration.visible" label="Cancelar" icon="pi pi-times" class="p-button p-component p-button-danger mr-2" @click="voltar()" />
            <Button v-if="!integration.visible" label="Voltar" icon="pi pi-spinner" class="p-button-outlined p-button-info mr-2"
                @click="voltar()"></Button>
        </div>

    </div>
</template>

<script>
import MunicipioService from '../../../service/MunicipioService';
import StorageService from '../../../service/StorageService';
import IntegracaoService from '../../../service/IntegracaoService';

export default {
    name: "MunicipioForm",
    
    props: {
        id: {
            type: Number,
            required: false
        },
        isApenasVisulizacao: {
            type: Boolean,
            required: false
        }
    },

    data() {
        return {
            dto: {
                municipio: {}
            },
            campoInvalido: {},
            integration: {},
            municipiosSelecionados: null,
            municipioFiltro: []
        }
    },

    
    created() {
        if (this.isApenasVisulizacao) {
            this.integration.visible = false;
        } else {
            this.getIntegrationOptons();
        }
    },

    mounted() {
        if (!!this.id) {
            MunicipioService.buscarPorId(this.id)
                .then(({ data }) => {
                    this.preencherForm(data);
                })
                .catch(error => {
                    this.dto = {};

                    const toast = {
                        severity: 'error',
                        summary: `Falha ao buscar os dados do município.`,
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!",
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;

                        if (!!message) {
                            toast["detail"] = message;
                        }
                    }

                    this.$toast.add(toast);
                });
        }
    },

    methods: {
        voltar() {
            this.$router.push(`/municipios`);
        },

        validarCamposObrigatorios() {
            let campos = [];

            if (!this.dto.codigo) {
                campos.push("Código IBGE");
                this.campoInvalido.codigo = true;
            }
            else {
                this.campoInvalido.codigo = false;
            }

            if (!this.dto.nome) {
                campos.push("Nome");
                this.campoInvalido.nome = true;
            }
            else {
                this.campoInvalido.nome = false;
            }

            if (!this.dto.uf) {
                campos.push("UF");
                this.campoInvalido.uf = true;
            }
            else {
                this.campoInvalido.uf = false;
            }

            if (campos.length > 0) {
                let mensagem = `Por favor, preencher o campo: ${campos[0]}.`;

                if (campos.length > 1) {
                    mensagem = "Por favor, preencher os campos: ";
                    mensagem += campos.join(", ");

                    const indice = mensagem.lastIndexOf(", ");
                    mensagem = `${mensagem.substring(0, indice)} e ${mensagem.substring(indice + 1, mensagem.length)}.`;
                }

                this.$toast.add({
                    severity: 'warn',
                    summary: 'Não foi possível salvar o Município.',
                    detail: mensagem,
                    life: 15000
                });
            }

            return campos.length === 0;
        },
         
        salvar() {
            if (!this.validarCamposObrigatorios()) return;
            const municipioDto = this.montarJson();
            MunicipioService.salvar(municipioDto)
                .then(({ data }) => {
                    if (!!data) {
                        const detalhe = 'O Município de código' + data.municipio.id + ' foi salvo com sucesso.';
                        StorageService.setMensagemToast("MUNICIPIO", 'success', 'Município salvo', detalhe, true);
                        this.voltar();
                    }
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Não foi possível salvar o Município.',
                        detail: "Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!",
                        life: 7500
                    }

                    if (!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        montarJson() {
            const municipioDto = {};
            municipioDto["id"] = this.dto.id;
            municipioDto["nome"] = this.dto.nome;
            municipioDto["codigo"] = this.dto.codigo;
            municipioDto["uf"] = this.dto.uf;
            return municipioDto;
        },

        preencherForm(dto) {
            const data = dto.municipio;
            const dados = {};
            dados["id"] = data.id;
            dados["nome"] = data.nome;
            dados["codigo"] = data.codigo;
            dados["uf"] = data.uf;
            this.dto = dados;
        },

        getIntegrationOptons() {
            const paginaAtual = "MUNICIPIO";
            IntegracaoService.getIntegrationOptions(paginaAtual)
                .then(({ data }) => {
                    if (data) {
                        this.integration = data;
                    }
                })
                .catch(error => {
                    this.$toast.add({
                        severity: 'info',
                        summary: 'PARÂMETROS DE PÁGINA NÂO DEFINIDOS',
                        detail: 'Você deve cadastrar os parâmetros para esta página no menu lateral em item: Parâmetros de Integração.',
                        life: 10000
                    });
                });
        },

        removerCaracteresEspeciais(event) {
            if(!event || !event.target || !event.target.value) return;
            event.target.value = event.target.value.replace(/[^a-zA-Z0-9]/g, "");
        },
    },

}

</script>